import { Injectable } from '@angular/core';


@Injectable()
export class ShadowRootHelper {
    toggleStyles = '.toggle-inner {box-shadow: none !important; width: 20px !important; height: 20px !important; left: 3px !important};';
    rangeStyles = '.range-pin {color: #702082 !important; transform: translate3d(-8px, 28px, 0px) scale(1) !important; ' +
        'font-size: 14px !important; top: 6px !important; padding: 0 !important; position: relative !important} ' +
        '.range-pin:after{position: absolute !important; content: "€m" !important; display: block; right: -25px !important; top: 0 !important;}' +
        '.range-knob{width: 30px !important;}' +
        '.range-knob-handle{width: 30px !important}' +
        '.range-knob:before{position: absolute; left: 5px; top: 5px;content: ""; width: 6px; height: 10px; background: url("assets/icon/caret-left--light.svg") no-repeat center center; }' +
        '.range-knob:after{position: absolute; right: 5px; top: 5px;content: ""; width: 6px; height: 10px; background: url("assets/icon/caret-right--light.svg") no-repeat center center;}';
    segmentButton = '.segment-button-indicator-background {background-color: #702082 !important; width: calc(100% - 4px) !important; margin-left: 2px !important}';


    public constructor() {
    }

    public injectStyles(
        shadowRootElement: HTMLElement,
        insertBeforeSelector: string,
        styles: string
    ) {
        const root = shadowRootElement.shadowRoot;
        let styleAlreadyAdded = false;
        const currentStyleTags = Array.from(root.querySelectorAll('style'));
        currentStyleTags.forEach((element: HTMLStyleElement, index) => {
            if (element.innerHTML === styles) {
                styleAlreadyAdded = true;
            }
        });
        if (styleAlreadyAdded === false) {
            const newStyleTag = document.createElement('style');
            newStyleTag.innerHTML = styles;
            root.insertBefore(newStyleTag, root.querySelector(insertBeforeSelector));
        }
        return true;
    }
}
