import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiService } from './services/api.service';
import { OpportunityHelper } from './helpers/opportunity.helper';
import { PickerHelper } from './helpers/picker.helper';
import { ShadowRootHelper } from './helpers/shadowRoot.helper';
import { FunctionsHelper } from './helpers/functions.helper';
import { DataSelectModalComponentModule } from './components/dataSelectModal/dataSelect.module';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AuthService } from './services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { UtilsService } from './services/utils.service';
import { OpportunitiesService } from './services/opportunities.service';
import { PreferencesService } from './services/preferences.service';
import { RangeHelper } from './helpers/range.helper';
import { Msal } from './services/msal.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { SearchService } from './services/search.service';
import { PushService } from './services/push.service';
import { SentryIonicErrorHandler } from './services/sentry.service';
import { FCM } from '@ionic-native/fcm/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        AppRoutingModule,
        DataSelectModalComponentModule,
        HttpClientModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        SocialSharing,
        ApiService,
        OpportunityHelper,
        PickerHelper,
        RangeHelper,
        ShadowRootHelper,
        FunctionsHelper,
        EmailComposer,
        AppVersion,
        AuthService,
        UtilsService,
        OpportunitiesService,
        PreferencesService,
        SearchService,
        Msal,
        FirebaseAnalytics,
        FileTransfer,
        File,
        Chooser,
        PushService,
        FCM,
        Camera,
        Deeplinks,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
