import { Injectable } from '@angular/core';
import { Opportunity } from '../models/opportunity';
// @ts-ignore
import * as metadata from '../../static/mockdata/metadata.json';
// @ts-ignore
import * as opportunities from '../../static/mockdata/opportunities.json';
import { OpportunitiesResponse } from '../models/getOpportunitiesResponse';
import { SERVER_ENDPOINT_API } from '../constans';
import { HttpErrorResponse } from '../models/httpErrorResponse';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import { NavController } from '@ionic/angular';

@Injectable()
export class ApiService {
    emptyOpportunity = {
        authorIsPartner: false,
        area: null,
        areaCapacity: null,
        areaSize: null,
        areaType: null,
        areaAssetSize: null,
        buyer: null,
        comments: [],
        adminComments: [],
        crcPartner: null,
        description: '',
        date: null,
        files: [],
        likes: null,
        modifiedDate: null,
        originator: null,
        opportunityOwner: null,
        opportunitySubmitter: null,
        opportunityType: null,
        otherCompaniesInvolved: null,
        sectorsIds: [],
        subSectorsIds: [],
        geographiesIds: [],
        countriesIds: [],
        subRegionsIds: [],
        opportunityTypesIds: [],
        seller: null,
        source: null,
        submissionDate: null,
        status: null,
        target: null,
        title: null,
        value: null,
        validityDate: null,
        isFollowing: false,
        valueRange: null
    };
    myOpportunities: Array<Opportunity> = [];
    myFollowedOpportunities = {
        dataDentons: [],
        dataExternal: []
    };
    opportunities;

    public constructor(
        private http: HttpClient,
        private authService: AuthService,
        private utils: UtilsService,
        private navCtrl: NavController
    ) {
    }

    getMetadata() {
        return metadata.default;
    }

    getOpportunites() {
        this.opportunities = opportunities.default.data;
        console.log(this.opportunities);
        return this.opportunities;
    }

    getOpportunity(opportunityId): Opportunity {
        const index = this.opportunities.findIndex(opp => opp.id === opportunityId);
        return this.opportunities[index];
    }

    getEmptyOpportunity(): Opportunity {
        // TODO fix types
        return Object.assign({}, this.emptyOpportunity) as any as Opportunity;
    }


    getFollowedOpportunites() {
        return this.myFollowedOpportunities;
    }

    public async getUsers(): Promise<any> {
        return this.http
            .get(SERVER_ENDPOINT_API + '/user/all', { headers: await this.authService.headers() })
            .toPromise()
            .then((resp: any) => {
                console.log('GET USERS: ', resp);
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                this.utils.presentToast(error.message);
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/azure-login');
                }
                return undefined;
            });
    }

    async reportShareEvent(oppId: number, sharedWithApp: string): Promise<void> {
        this.http
            .post(
                SERVER_ENDPOINT_API + `/opportunities/${oppId}/share`,
                {
                    socialSite: sharedWithApp,
                },
                { headers: await this.authService.headers() }
            )
            .toPromise()
            .then(resp => {
                console.log('Report share event success', resp);
            })
            .catch((err: HttpErrorResponse) => {
                console.warn('Report share event error', err);
            });
    }
}
