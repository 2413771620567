import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FormGroup } from '@angular/forms';
import { FunctionsHelper } from '../../helpers/functions.helper';
import { OpportunityHelper } from '../../helpers/opportunity.helper';

@Component({
    selector: 'app-checkbox-tree-inputs',
    templateUrl: 'checkboxTreeInputs.component.html',
    styleUrls: ['checkboxTreeInputs.component.scss']
})
export class CheckboxTreeInputsComponent implements OnInit {
    @Input() public dataArray: Array<any> = [];
    @Input() public title;
    @ViewChild('sellerInput', { read: ElementRef, static: false }) public sellerInput: ElementRef;
    @ViewChild('buyerInput', { read: ElementRef, static: false }) public buyerInput: ElementRef;
    @ViewChild('otherCompaniesInput', { read: ElementRef, static: false }) public otherCompaniesInput: ElementRef;
    public initialSelected: Array<string> = [];
    public checksArray: Array<{ name: string, isSelected: boolean, display: boolean }>;
    public selected = [];
    public type = '';
    public form: FormGroup;
    public otherCompaniesInvolved = '';
    public typesArray: Array<any> = [];

    constructor(
        private navParams: NavParams,
        private modalCtrl: ModalController,
        public functionsHelper: FunctionsHelper,
        private ngZone: NgZone,
        public opportunityHelper: OpportunityHelper
    ) {
        this.dataArray = this.navParams.get('data');
        this.initialSelected = this.navParams.get('selected') ? this.navParams.get('selected').split(', ') : [];
        this.title = this.navParams.get('title');
    }


    ngOnInit() {
        this.selected = [];
    }

    close() {
        this.modalCtrl.dismiss({
            data: this.dataArray,
            canceled: true
        });
    }

    done() {
        this.modalCtrl.dismiss({
            data: this.dataArray,
            canceled: false
        });
    }

    filter(event) {
        const query = event.target.value;
        this.ngZone.run(() => {
            if (query && query.trim() !== '') {
                this.checksArray = this.checksArray.filter((item) => {
                    return (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
                });
            }
        });
    }

    /*
        Funkci nechávám pro případ, že si rozmislý zase políčka dynamicky přidávat
        addCompany(data) {
            console.log(data);
            const index = this.typesArray.findIndex(a => a.type === data.title);
            this.typesArray[index].companies.push({
                value: ''
            });
            console.log(this.typesArray);
        }
    */
    goNext(event, input) {
        if (this[input].nativeElement.localName.toLowerCase() === 'ion-input') {
            this[input].nativeElement.querySelector('input').focus();
        } else {
            this[input].nativeElement.querySelector('textarea').focus();

        }
    }

    getIndex(data) {
        return this.typesArray.findIndex(a => a.type === data.title);
    }


}
