import { Injectable } from '@angular/core';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { ActionSheetController } from '@ionic/angular';
import { Opportunity } from '../models/opportunity';
import { UtilsService } from './utils.service';
import * as moment from 'moment';
import { FunctionsHelper } from '../helpers/functions.helper';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ApiService } from './api.service';
import { OtherMetricsService } from './other-metrics.service';

@Injectable({
    providedIn: 'root'
})
export class ShareService {

    constructor(
        private actionSheetCtrl: ActionSheetController,
        private emailComposer: EmailComposer,
        private socialSharing: SocialSharing,
        private utils: UtilsService,
        private functionsHelper: FunctionsHelper,
        private apiSvc: ApiService,
        private otherMetricService: OtherMetricsService,
    ) {
    }

    share(opp: Opportunity) {
        this.actionSheetCtrl
            .create({
                buttons: [{
                    text: 'Share with Mail app',
                    icon: 'mail-outline',
                    handler: () => this.shareWithEmail(opp)
                }, {
                    text: 'Share with share sheet',
                    icon: 'share-outline',
                    handler: () => this.shareWithSheet(opp)
                }, {
                    text: 'Cancel',
                    role: 'cancel',
                }]
            })
            .then(sheet => sheet.present());
    }

    private shareWithEmail = async (opp: Opportunity) => {
        const hasAccount = await this.emailComposer.hasAccount();
        if (!hasAccount) {
            this.utils.presentErrorAlert('Error while sharing: No iOS mail client account is available.');
            return;
        }
        try {
            const email = {
                to: '',
                subject: opp.title,
                body: `
                    <p><strong style="color: #702082"> ${opp.title} </strong><br/>
                    ${this.transformDate(opp.submissionDate, 'D MMM YYYY')}  by  ${opp.originator} <br/></p>
                    <p><strong>Value:</strong><br/> ${this.getEmailValue(opp)} </p>
                    <p><strong>Source:</strong><br/> ${this.getEmailSource(opp)} </p>
                    <hr>
                    <p><strong style="text-transform: uppercase">Description</strong><br/> ${(opp.description ? opp.description : 'Not specified')} </p>
                    <p><strong style="text-transform: uppercase">Opportunity type</strong><br/> ${this.getEmailOpportunityType(opp)} </p>
                    <p><strong style="text-transform: uppercase">Sector</strong><br/> ${this.getEmailSectors(opp)} </p>
                    <p><strong style="text-transform: uppercase">Other metrics</strong><br/> ${this.getEmailOtherMetrics(opp)} </p>
                    <p><strong style="text-transform: uppercase">Geography</strong><br/> ${this.getEmailGeography(opp)} </p>
                    <p><a href="${this.getOpportunityDeeplink(opp.id)}">Open in app</a></p>
                `,
                isHtml: true
            };
            await this.emailComposer.open(email);
            this.onShareSuccess(opp.id, 'Mail app');
        } catch (e) {
            console.log('ERROR WHEN SHARING: ', e);
        }
    }

    private shareWithSheet = async (opp: Opportunity) => {
        const result: { completed: boolean, app: string } = await this.socialSharing.shareWithOptions({
            subject: opp.title,
            message: opp.title,
            url: this.getOpportunityDeeplink(opp.id),
        });
        if (result.completed) {
            this.onShareSuccess(opp.id, result.app);
        }
    }

    private onShareSuccess(oppId: number, app: string) {
        this.apiSvc.reportShareEvent(oppId, app);
    }

    private transformDate(date, format) {
        return moment(date).format(format);
    }

    private getOpportunityDeeplink(oppId: number) {
        return `dotapp://?opportunity=${oppId}`;
    }

    private getEmailOpportunityType(item) {
        if (item.opportunityTypesIds.length > 0) {
            let opt = `
                    ${item.opportunityTypesIds.map((optId, index) =>
                '<span style="margin-left: 10px;">' + this.functionsHelper.getOpportunityTypeTitle(optId) + '</span>' + (index + 1 < item.opportunityTypesIds.length ? ', ' : ''))
                    .join('')}
                    <br/>
                `;
            if (this.functionsHelper.includesSpecialTypeId(item.opportunityTypesIds)) {
                if (item.target) {
                    opt += `<strong style="margin-left: 10px;">Target</strong><br/><span style="margin-left: 20px;">${item.target}</span><br/>`;
                }
                if (item.seller) {
                    opt += `<strong style="margin-left: 10px;">Seller</strong><br/><span style="margin-left: 20px;">${item.seller}</span><br/>`;
                }
                if (item.buyer) {
                    opt += `<strong style="margin-left: 10px;">Buyer</strong><br/><span style="margin-left: 20px;">${item.buyer}</span><br/>`;
                }
            }
            if (item.otherCompaniesInvolved) {
                if (this.functionsHelper.includesSpecialTypeId(item.opportunityTypesIds)) {
                    opt += `<strong style="margin-left: 10px;">Other companies involved</strong><br/><span style="margin-left: 20px;">${item.otherCompaniesInvolved}</span><br/>`;
                }
                if (!this.functionsHelper.includesSpecialTypeId(item.opportunityTypesIds)) {
                    opt += `<strong style="margin-left: 10px;">Companies involved</strong><br/><span style="margin-left: 20px;">${item.otherCompaniesInvolved}</span><br/>`;
                }
            }
            return opt;
        } else {
            return 'Not Specified';
        }
    }

    private getEmailGeography(item) {
        if (item.geographiesIds.length > 0) {
            let regions = '';
            let subRegions = '';
            let countries = '';
            for (const regionId of item.geographiesIds) {
                if (this.functionsHelper.hasRegionAllSelected(item, regionId)) {
                    regions += `<strong style="margin-left: 10px;">${this.functionsHelper.getRegionTitle(regionId)}</strong><br/><span style="margin-left: 20px;">All</span><br/>`;
                }
                if (!this.functionsHelper.hasRegionAllSelected(item, regionId) && !this.functionsHelper.hasParentSubRegion(regionId)) {
                    regions += `<strong style="margin-left: 10px">${this.functionsHelper.getRegionTitle(regionId)}</strong><br/>`;
                    countries += this.functionsHelper.selectedRegionCountries(item, regionId).map((countryId, index) =>
                        `<span>${this.functionsHelper.getCountryTitle(countryId)}</span>
                            ${(index + 1 < this.functionsHelper.selectedRegionCountries(item, regionId).length ? ', ' : '')}`
                    ).join('');
                    regions += `<span style="margin-left: 20px">${countries}</span><br/>`;

                }
                if (!this.functionsHelper.hasRegionAllSelected(item, regionId) && this.functionsHelper.hasParentSubRegion(regionId)) {
                    subRegions = '';
                    item.subRegionsIds.forEach((subRegionId) => {
                        if (this.functionsHelper.hasSubRegionAllSelected(item, subRegionId)) {
                            regions += `<strong style="margin-left: 10px">${this.functionsHelper.getRegionTitle(regionId)}
                                - ${this.functionsHelper.getSubRegionTitle(subRegionId)}</strong><br/><span style="margin-left: 20px;">All</span><br/>`;
                        } else {
                            regions += `<strong style="margin-left: 10px">${this.functionsHelper.getRegionTitle(regionId)}
                                - ${this.functionsHelper.getSubRegionTitle(subRegionId)}</strong><br/>`;
                            countries = '';
                            countries += this.functionsHelper.selectedSubRegionCountries(item, subRegionId).map((countryId, index) =>
                                `<span>${this.functionsHelper.getCountryTitle(countryId)}</span>
                                    ${(index + 1 < this.functionsHelper.selectedSubRegionCountries(item, subRegionId).length ? ', ' : '')}`
                            ).join('');
                            regions += `<span style="margin-left: 20px">${countries}</span><br/>`;
                        }
                    });
                }

            }
            return regions;
        } else {
            return 'Not Specified';
        }
    }

    private getEmailOtherMetrics(item): string {
        if (!item.otherMetrics.length) {
            return 'Not Specified';
        }
        let result = '';
        for (const config of this.otherMetricService.metricsConfig) {
            const matchesInOpp = this.otherMetricService.filterOtherMetricsByConfig(item.otherMetrics, config);
            if (!matchesInOpp.length) {
                continue;
            }
            result += `<strong style="margin-left: 10px">${config.title}</strong><br/>`;
            if (config.children) {
                config.children.forEach(childConfig => {
                    const matchesForChild = this.otherMetricService.filterOtherMetricsByConfig(matchesInOpp, childConfig);
                    if (!matchesForChild.length) {
                        return;
                    }
                    result += `<strong style="margin-left: 20px">${childConfig.title}</strong><br/>`;
                    const matchesString = this.otherMetricService.otherMetricsToString(matchesForChild, config);
                    result += `<span style="margin-left: 30px">${matchesString}</span><br/>`;
                });
            } else {
                const matchesString = this.otherMetricService.otherMetricsToString(matchesInOpp, config);
                result += `<span style="margin-left: 20px">${matchesString}</span><br/>`;
            }
        }
        return result;
    }

    private getEmailSectors(item) {
        if (item.sectorsIds.length > 0) {
            let sectors = '';
            let subSectors = '';
            for (const sectorId of item.sectorsIds) {
                if (this.functionsHelper.hasAllSelected(item, sectorId)) {
                    sectors += `<strong style="margin-left: 10px;">${this.functionsHelper.getSectorTitle(sectorId)}</strong><br/><span style="margin-left: 20px;">All</span><br/>`;
                } else {
                    sectors += `<strong style="margin-left: 10px;">${this.functionsHelper.getSectorTitle(sectorId)}</strong><br/>`;
                    subSectors += this.functionsHelper.getSelectedSectorsTitles(item, sectorId).map((child, index) =>
                        `<span>${child.text}</span>${(index + 1 < this.functionsHelper.getSelectedSectorsTitles(item, sectorId).length ? ', ' : '')}`
                    ).join('');
                    sectors += `<span style="margin-left: 20px">${subSectors}</span><br/>`;
                }
            }
            return sectors;
        } else {
            return 'Not Specified';
        }
    }

    private getEmailSource(item): string {
        const title = this.functionsHelper.getSourceTitle(item.sourceId);
        return title || 'Not specified';
    }

    private getEmailValue(item) {
        if (item.value && (!item.useValueRange && !item.valueRangeTo && !item.valueRangeFrom)) {
            return `${item.value}€m`;
        } else if (!item.value && (item.useValueRange || (item.valueRangeTo && item.valueRangeFrom))) {
            return `${Number(item.valueRangeFrom)}€m - ${(item.valueRangeTo === 5001 ? '> 5000' : Number(item.valueRangeTo))}€m`;
        } else {
            return 'Not specified';
        }
    }
}
