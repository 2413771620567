import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { User } from '../models/user';
import { MsalInitOptions } from '../models/msalInitOptions';
import * as moment from 'moment';
import * as JwtDecode from 'jwt-decode';
import { Router } from '@angular/router';
import { GRAPH_TOKEN, SERVER_ENDPOINT_API } from '../constans';
import { Msal } from './msal.service';
import { Platform } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable()
export class AuthService {

    public userInfoObtainedSubject: Subject<void> = new Subject<void>();
    private isMsalInited = false;
    private optionsServer: MsalInitOptions = {
        brokerRedirectUri: true,
        scopes: ['api://c67126fd-2506-4801-ae59-88d836bef980/demo.read']
    };

    public constructor(
        private http: HttpClient,
        private utils: UtilsService,
        private msal: Msal,
        private utilsService: UtilsService,
        private router: Router,
        private platform: Platform
    ) {
    }

    public _user: User;

    get user(): User {
        if (!this._user) {
            this._user = JSON.parse(localStorage.getItem('DOT-user'));
        }
        return this._user;
    }

    // tslint:disable-next-line:adjacent-overload-signatures
    set user(value: User) {
        this._user = value;
    }

    public _metadata: any;

    get metadata(): any {
        if (!this._metadata) {
            this._metadata = JSON.parse(localStorage.getItem('DOT-metadata'));
        }
        return this._metadata;
    }

    private _token: string;

    get token(): string {
        if (!this._token) {
            this._token = localStorage.getItem('DOT-server-token');
        }
        return this._token;
    }

    // tslint:disable-next-line:adjacent-overload-signatures
    set token(value: string) {
        this._token = value;
    }

    public async getMetadata(): Promise<any> {
        return this.http
            .get(`${SERVER_ENDPOINT_API}/lists`, { headers: await this.headers() })
            .toPromise()
            .then((resp: any) => {
                this._metadata = resp;
                localStorage.setItem('DOT-metadata', JSON.stringify(this._metadata));
            })
            .catch((error: HttpErrorResponse) => {
                return this.utils.presentToast(error.error.message, 5000);
            });
    }

    public async getUserInfoWithStats(): Promise<User> {
        const headers: any = await this.headers();
        if (!headers) {
            this.utils.presentToast('Cannot sign in.', 5000);
            return undefined;
        }
        return this.http
            .get(`${SERVER_ENDPOINT_API}/user/sign-in`, { headers })
            .toPromise()
            .then((resp: any) => {
                this._user = resp;
                console.log('/user/sign-in USER:', JSON.stringify(this._user));
                localStorage.setItem('DOT-user', JSON.stringify(this.user));
                this.userInfoObtainedSubject.next();
                return resp;
            })
            .catch(async (error: HttpErrorResponse) => {
                await this.utilsService.dismissLoader();
                console.log(error);
                if (error.status === 401) {
                    this.utils.presentToast('Cannot sign in. Token is maybe expired.', 5000);
                } else {
                    this.utils.presentToast('Cannot sign in.', 5000);
                }
                await this.signOut(false);
                return undefined;
            });
    }

    public async headers(): Promise<any> {
        this.token = await this.getToken();
        if (!this.token) {
            return undefined;
        }
        const headersRequest = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
        };
        return headersRequest;
    }

    public async navToNextPageAfterSigningIn() {
        const logged = JSON.parse(localStorage.getItem('DOT-logged'));
        if (logged) {
            const user = JSON.parse(localStorage.getItem('DOT-user'));

            if (user.isOnboarded !== true) {
                await this.router.navigateByUrl('/onboarding');
            } else if (user.isOnboarded === true && user.isPreferencesSet !== true) {
                await this.router.navigateByUrl('/preferences');
            } else {
                await this.router.navigateByUrl('/opportunities');
            }
        }
    }

    public clearStorage(): void {
        this.token = undefined;
        this.user = undefined;
        localStorage.removeItem('DOT-logged');
        localStorage.removeItem('DOT-user');
        localStorage.removeItem('DOT-server-token');
        localStorage.removeItem('DOT-preferences-data');

    }

    public async signOut(showDialog: boolean): Promise<void> {
        await this.utilsService.presentLoader();
        this.clearStorage();
        if (this.platform.is('cordova')) {
            try {
                await this.msal.signOut();
            } catch (e) {
                console.log('signing out error:', e);
            }
        }
        if (showDialog) {
            this.utils.presentToast('You were signed out.');
        }
        await this.utilsService.dismissLoader();
    }

    public async signIn(email: any, password: any): Promise<User> {
        return this.http
            .post(`${SERVER_ENDPOINT_API}/auth/login`, { username: email, password })
            .toPromise()
            .then(async (resp: { access_token: string }) => {
                this.token = resp.access_token;
                localStorage.setItem('DOT-server-token', this.token);
                localStorage.setItem('DOT-logged', JSON.stringify(true));
                return await this.getUserInfoWithStats();
            })
            .catch((error: HttpErrorResponse) => {
                this.utils.presentToast(error.error.message);
                return undefined;
            });
    }

    public async signInWithAzure(): Promise<void> {
        await this.utilsService.presentLoader();
        const user: User = await this.getUserInfoWithStats();
        console.log('auth sign in', user);
        if (user) {
            localStorage.setItem('DOT-logged', JSON.stringify(true));
            if (!localStorage.getItem('DOT-preferences')) {
                localStorage.setItem('DOT-preferences', JSON.stringify({
                    filled: false,
                    userId: 0
                }));
            }
            this.navToNextPageAfterSigningIn();
        }
        await this.utilsService.dismissLoader();
    }

    /**
     * Funkce ziskava aktualni token. Pokud je aktualni token validni, vrati se ten.
     */
    private async getToken(): Promise<string> {

        const currentToken = this.token;

        if (currentToken) {
            const decodedToken: any = JwtDecode(currentToken);
            const tokenExp: number = moment.utc(decodedToken.exp * 1000).valueOf();
            const now: number = moment.utc().valueOf();
            // token jeste nevyprsel
            if (tokenExp > now) {
                return currentToken;
            }
        }

        const user: User = this.user;
        if (user && !user.isFromAAD) {
            // Uzivatel neni registrovany pres AAD, ale email a heslo
            return undefined;
        }

        if (!this.platform.is('cordova')) {
            return GRAPH_TOKEN;
        }

        if (!this.isMsalInited) {
            console.log('MSAL is not initialized');
            this.isMsalInited = await this.msal
                .msalInit(this.optionsServer)
                .then((initResult) => {
                        console.log('init ok', initResult);
                        return true;
                    },
                    (err) => {
                        console.log('Error result:', err);
                        return false;
                    });
        }

        if (this.isMsalInited) {
            return this.msal
                .signInSilent()
                .then(jwt => {
                    console.log(jwt);
                    return jwt;
                })
                .catch(error => {
                    console.log('AD ERROR: try sign in interactive:', error);
                    return this.msal
                        .signInInteractive({ prompt: 'LOGIN' })
                        .then(jwt => {
                            console.log(jwt);
                            return jwt;
                        })
                        .catch(e => {
                            console.log('AD ERROR: sign in interactive failed', e);
                            return undefined;
                        });
                });
        } else {
            return undefined;
        }
    }

    public async updateUser(isOnboarded, isPreferencesSet) {
        const user = JSON.parse(localStorage.getItem('DOT-user'));
        if (isOnboarded) {
            user.isOnboarded = isOnboarded;
        }
        if (isPreferencesSet) {
            user.isPreferencesSet = isPreferencesSet;
        }
        return this.http
            .put(`${SERVER_ENDPOINT_API}/user`, user, { headers: await this.headers() })
            .toPromise()
            .then((resp: any) => {
                this._user = resp;
                localStorage.setItem('DOT-user', JSON.stringify(this._user));
            })
            .catch((error: HttpErrorResponse) => {
                return this.utils.presentToast(error.error.message, 5000);
            });
    }

    public async updateUserNotifications(data) {
        return this.http
            .put(`${SERVER_ENDPOINT_API}/user/notificationsSettings`, data, { headers: await this.headers() })
            .toPromise()
            .then((resp: any) => {
                console.log('UPDATE NOTIF SETTINGS', resp);
                this._user.notificationsSettings = resp;
                localStorage.setItem('DOT-user', JSON.stringify(this._user));
            })
            .catch((error: HttpErrorResponse) => {
                return this.utils.presentToast(error.error.message, 5000);
            });
    }
}
