import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { EXPORT_OPPORTUNITIES_MAX_COUNT, SERVER_ENDPOINT_API } from '../constans';
import { AuthService } from './auth.service';
import { UtilsService } from './utils.service';
import * as moment from 'moment';
import { ExportFormat } from '../models/exportFormatEnum';
import { ExportGroup } from '../models/exportGroupEnum';

const API_FILE_TYPES = {
    [ExportFormat.PDF]: 'pdf',
    [ExportFormat.POWERPOINT]: 'ppt',
    [ExportFormat.WORD]: 'docx',
};
const API_SEARCH_TYPES = {
    simple: 'simple',
    search: 'advanced',
};

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    ticksShown = false;
    exportButtonsShown = false;
    checkedItems: { [oppId: string]: true } = {};
    checkedItemsTotal: number;
    exportLimit = EXPORT_OPPORTUNITIES_MAX_COUNT;
    selectedExportGroup: ExportGroup;
    search: {
        type: string,
        data: any,
    };

    constructor(
        private http: HttpClient,
        private socialSharing: SocialSharing,
        private utilsSvc: UtilsService,
        private authSvc: AuthService,
    ) {
    }

    showExportButtons(group: ExportGroup, searchType?: string, searchData?: any) {
        this.clear();
        this.exportButtonsShown = true;
        this.selectedExportGroup = group;
        this.search = searchType ? {
            type: searchType,
            data: searchData,
        } : undefined;
    }

    showTicks() {
        this.ticksShown = true;
    }

    clear() {
        this.ticksShown = false;
        this.exportButtonsShown = false;
        this.checkedItems = {};
        this.checkedItemsTotal = 0;
        this.selectedExportGroup = undefined;
        this.search = undefined;
    }

    toggleChecked(oppId: number) {
        if (this.checkedItems[oppId]) {
            delete this.checkedItems[oppId];
        } else if (this.checkedItemsTotal < this.exportLimit) {
            this.checkedItems[oppId] = true;
        }
        this.checkedItemsTotal = Object.keys(this.checkedItems).length;
    }

    async doExport(exportAll: boolean, format: ExportFormat): Promise<void> {
        (window as any).mHttp = this.http;
        (window as any).mSocialSharing = this.socialSharing;

        const fileType = API_FILE_TYPES[format];
        const date = moment().format().split('T')[0];
        const localFilename = `Opportunities-${date}.${fileType}`;

        const body = exportAll
            ? {
                group: this.selectedExportGroup,
                items: null,
                search: this.search ? {
                    type: API_SEARCH_TYPES[this.search.type],
                    data: this.search.data
                } : null,
            }
            : {
                group: ExportGroup.ID,
                items: Object.keys(this.checkedItems).map(key => +key),
                search: null,
            };

        await this.utilsSvc.presentLoader();
        try {
            const resp = await this.http
                .post(
                    `${SERVER_ENDPOINT_API}/opportunities/export/${fileType}`,
                    body,
                    {
                        headers: await this.authSvc.headers(),
                        responseType: 'blob',
                        observe: 'response',
                    })
                .toPromise();
            const base64 = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = err => reject(err);
                reader.readAsDataURL(resp.body);
            });
            const dataUrl = `df:${localFilename};${base64}`;
            const options = {
                files: [dataUrl]
            };
            this.socialSharing.shareWithOptions(options);
            this.clear();
        } catch (err) {
            console.log(err);
            this.utilsSvc.presentErrorAlert('Error while exporting opportunities.');
            throw err;
        } finally {
            await this.utilsSvc.dismissLoader();
        }
    }
}
