import { Component, NgZone, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { OpportunityHelper } from '../../helpers/opportunity.helper';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-data-select-modal',
    templateUrl: 'dataSelect.component.html',
    styleUrls: ['dataSelect.component.scss']
})
export class DataSelectModalComponent implements OnInit {

    public showCheckboxTree = false;
    public dataArray: Array<any> = [];
    public dataArrayInitial: string;
    public initialSelected: Array<string> = [];
    public checksArray: Array<{ id: number, value: string, isSelected: boolean }>;
    public selected = [];
    public title = '';
    public subtitle = '';
    public type = '';
    public isTree = false;
    public treeInputs = false;

    constructor(
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private ngZone: NgZone,
        public apiService: ApiService,
        public opportunityHelper: OpportunityHelper,
        public authService: AuthService,
    ) {
        this.dataArray = this.navParams.get('data');
        this.initialSelected = this.navParams.get('selected') ? this.navParams.get('selected') : [];
        this.title = this.navParams.get('title');
        this.subtitle = this.navParams.get('subtitle');
        this.type = this.navParams.get('type');
        this.isTree = this.navParams.get('isTree');
        this.initializeItems();
    }

    ngOnInit() {
        this.selected = [];
    }

    handleSelect(model) {
        if (model.isSelected) {
            this.selected.push(model.text);
        } else {
            this.selected.splice(this.selected.indexOf(model.text), 1);
        }
    }


    initializeItems() {
        if (this.isTree) {
            this.showCheckboxTree = true;
            switch (this.type) {
                case 'sectors':
                    for (const sector of this.dataArray) {
                        sector.isSelected = !!sector.isSelected;
                        sector.isAllSelected = !!sector.isAllSelected;
                        sector.isClosed = sector.isClosed !== false;
                        sector.children = sector.children ? sector.children : [];
                    }
                    this.dataArrayInitial = JSON.stringify(this.dataArray);
                    break;
                case 'geography':
                    for (const region of this.dataArray) {
                        region.isSelected = !!region.isSelected;
                        region.isAllSelected = !!region.isAllSelected;
                        region.isClosed = region.isClosed !== false;
                        region.children = region.children ? region.children : [];
                        if (this.hasParentSubRegion(region)) {
                            region.subRegions = region.subRegions ? region.subRegions : [];
                        }
                        region.isGeo = true;
                    }
                    this.dataArrayInitial = JSON.stringify(this.dataArray);
                    break;
            }
        } else {
            this.checksArray = [];
            for (const c of this.dataArray) {
                this.checksArray.push({
                    id: c.id,
                    value: c.value,
                    isSelected: this.initialSelected.includes(c) ? this.initialSelected.includes(c) : this.selected.includes(c)
                });
            }
            this.dataArrayInitial = JSON.stringify(this.checksArray);
        }
    }

    hasParentSubRegion(region) {
        const subRegions = this.authService.metadata.subRegions;
        return subRegions.findIndex(sr => sr.geographyId === region.id) > -1;
    }

    close() {
        let returnedData;
        if (this.isTree) {
            switch (this.type) {
                case 'sectors':
                    returnedData = this.compareData(this.dataArray, this.dataArrayInitial);
                    break;
                case 'geography':
                    returnedData = this.compareData(this.dataArray, this.dataArrayInitial);
                    break;
                case 'opportunity':
                    returnedData = this.compareData(this.dataArray, this.dataArrayInitial);
                    break;
            }
        } else {
            returnedData = this.compareData(this.checksArray, this.dataArrayInitial);

        }


        this.modalCtrl.dismiss({
            data: returnedData,
            canceled: true,
            dataChanged: false
        });
    }

    done() {
        this.modalCtrl.dismiss({
            data: this.isTree ? this.dataArray : this.checksArray,
            canceled: false
        });
    }

    filter(event) {
        const query = event.target.value;
        this.initializeItems();
        this.ngZone.run(() => {
            if (query && query.trim() !== '') {
                this.dataArray = this.dataArray.filter((item) => {
                    return (item.text.toLowerCase().indexOf(query.toLowerCase()) > -1);
                });
            }
        });
    }

    hasSelected(children) {
        const index = children.findIndex(ch => ch.isSelected === true);
        return index > -1;
    }


    compareData(dataArray, dataArrayInitial) {
        let finalData;
        const dataInitialParsed = JSON.parse(dataArrayInitial);
        const dataCopy = JSON.stringify(dataArray);
        const dataCopyParsed = JSON.parse(dataCopy);
        for (const data of dataInitialParsed) {
            delete data.isClosed;
            if (data.subSector) {
                for (const s of data.subSector) {
                    delete s.isClosed;
                }
            }
        }
        for (const data of dataCopyParsed) {
            delete data.isClosed;
            if (data.subSector) {
                for (const s of data.subSector) {
                    delete s.isClosed;
                }
            }
        }
        if (JSON.stringify(dataInitialParsed) === JSON.stringify(dataCopyParsed)) {
            finalData = dataArray;
        } else {
            finalData = JSON.parse(dataArrayInitial);
        }

        return finalData;
    }
}
