import { Injectable } from '@angular/core';

@Injectable()
export class PickerHelper {


    public constructor() {
    }


    getColumns(numColumns, columnOptions, selectedIndex) {
        const columns = [];
        for (let i = 0; i < numColumns; i++) {
            columns.push({
                name: `col-${i}`,
                options: this.getColumnOptions(i, columnOptions),
                selectedIndex
            });
        }
        return columns;
    }

    getColumnOptions(columnIndex, columnOptions) {
        const options = [];
        for (const i in columnOptions[columnIndex]) {
            if (columnOptions[columnIndex].hasOwnProperty(i)) {
                options.push({
                    text: columnOptions[columnIndex][i].value ? columnOptions[columnIndex][i].value : columnOptions[columnIndex][i].name,
                    value: columnOptions[columnIndex][i].id
                });
            }
        }
        return options;
    }

}
