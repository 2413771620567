import { Injectable } from '@angular/core';


@Injectable()
export class RangeHelper {
    private manualChanged = false;

    public lastValueLower = 0;
    public lastValueUpper = 38;
    public valueRange = { lower: 0, upper: 10000000 };
    public range = { lower: this.lastValueLower, upper: this.lastValueUpper };
    public tics: Array<number> = [0, 0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9,
        10, 20, 30, 40, 50, 60, 70, 80, 90,
        100, 200, 300, 400, 500, 600, 700, 800, 900,
        1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 10000000];

    public constructor() {
    }


    updateValues(form) {
        if (this.manualChanged) {
            this.manualChanged = false;
            return;
        }
        this.valueRange.lower = this.tics[this.range.lower];
        this.valueRange.upper = this.tics[this.range.upper];
        if (this.lastValueLower !== this.range.lower) {
            form.controls.valueRangeFrom.setValue(this.valueRange.lower);
        }
        if (this.lastValueUpper !== this.range.upper) {
            if (this.range.upper === 38) {
                form.controls.valueRangeTo.setValue('> 5000');
            } else {
                form.controls.valueRangeTo.setValue(this.valueRange.upper);
            }
        }
        this.lastValueLower = this.range.lower;
        this.lastValueUpper = this.range.upper;
    }

    updateValue(form) {
        let lower = 0;
        let upper = this.tics[this.tics.length - 1];

        let min = Number(form.controls.valueRangeFrom.value);
        let max = Number(form.controls.valueRangeTo.value);

        min = isNaN(min) || min < lower ? lower : min;
        max = isNaN(max) || max > upper ? upper : max;


        for (let i = 0; i < this.tics.length - 1; i++) {
            if (this.tics[i] <= min) {
                lower = i;
            }
        }

        for (let i = this.tics.length - 1; i >= 0; i--) {
            if (this.tics[i] >= max) {
                upper = i;
            }
        }

        if (form.controls.valueRangeTo.value > 5000) {
            form.controls.valueRangeTo.setValue('> 5000');
        }
        this.lastValueLower = lower;
        this.lastValueUpper = upper;
        this.manualChanged = true;
        form.controls.valueRange.setValue({
            lower,
            upper
        });
    }


}
