import { Injectable } from '@angular/core';

@Injectable()
export class OpportunityHelper {
    public constructor() {
    }

    isFollowed(opportunity): boolean {
        if (opportunity) {
            return opportunity.isFollowing;
        }
        return false;
    }

}
