import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  constructor(
    private fa: FirebaseAnalytics,
    private platform: Platform,
    private router: Router,
  ) {
  }

  public initialize(): void {
    this.router.events.subscribe(async evt => {
      if (evt instanceof NavigationEnd) {
        const screen = evt.url.substring(1, evt.url.indexOf(';') > -1 ? evt.url.indexOf(';') : evt.url.length);
        if (screen) {
          console.log('Analytics setCurrentScreen', screen);
          try {
            if (this.platform.is('cordova')) {
              await this.fa.setCurrentScreen(screen);
            }
          } catch (e) {
            console.log('Analytics setCurrentScreen error', e);
          }
        }
      }
    });
  }

  public async setUserId(id: string): Promise<void> {
    console.log('Analytics setUserId', id);
    try {
      if (this.platform.is('cordova')) {
        await this.fa.setUserId(id);
      }
    } catch (e) {
      console.log('Analytics setUserId error', e);
    }
  }

  public async logEvent(event: {
    name: string;
    params?: { [key: string]: any };
  }): Promise<void> {
    console.log('Analytics logEvent', event);
    try {
      if (this.platform.is('cordova')) {
        await this.fa.logEvent(event.name, event.params || {});
      }
    } catch (e) {
      console.log('Analytics logEvent error', e);
    }
  }

  public async setUserProperty(name: string, value: string): Promise<void> {
    console.log('Analytics setUserProperty', name, value);
    try {
      if (this.platform.is('cordova')) {
        await this.fa.setUserProperty(name, value);
      }
    } catch (e) {
      console.log('Analytics setUserProperty error', e);
    }
  }
}
