import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { SERVER_ENDPOINT_API } from '../constans';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { AlertController, Platform } from '@ionic/angular';
import { FCM } from '@ionic-native/fcm/ngx';
import { Router } from '@angular/router';
import { FunctionsHelper } from '../helpers/functions.helper';
import { OpportunitiesService } from './opportunities.service';

declare const FCMPlugin;

@Injectable()
export class PushService {

    private token: string;
    private onTokenRefreshSubscription: Subscription;
    private onNotificationSubscription: Subscription;

    public constructor(
        private fcm: FCM,
        private platform: Platform,
        private http: HttpClient,
        private authService: AuthService,
        private alertCtrl: AlertController,
        private router: Router,
        private functionHelper: FunctionsHelper,
        private opportunitiesService: OpportunitiesService
    ) {
    }

    public async initialize(): Promise<void> {
        if (!this.platform.is('cordova')) {
            return;
        }
        try {
            const perm: boolean = await this.fcm.hasPermission();
            if (!perm) {
                await FCMPlugin.requestPushPermissionIOS();
            }
            this.onTokenRefresh();
            this.onNotification();

            this.authService.userInfoObtainedSubject.asObservable().subscribe(() => {
                this.getToken();
            });

        } catch (e) {
            console.log(e);
        }


    }

    public async deleteTokenOnServer(): Promise<void> {
        if (this.token) {
            console.log('Deleting token from server', this.token);
            await this.http.delete(`${SERVER_ENDPOINT_API}/push-notifications/device-token/${this.token}`,
                { headers: await this.authService.headers() })
                .toPromise()
                .then(resp => {
                    console.log('Token deleted', resp);
                })
                .catch(error => {
                    console.log('Token delete error', error);
                });
        }
    }

    public getToken(): void {
        this.fcm
            .getToken()
            .then(token => {
                console.log('getToken', JSON.stringify(token));
                this.token = token;
                this.sendTokenToServer();
            })
            .catch(error => {
                console.log('getToken error', error);
            });
    }

    private async sendTokenToServer(): Promise<void> {
        if (this.token) {
            console.log('Sending token to server', JSON.stringify(this.token));
            this.http
                .post(`${SERVER_ENDPOINT_API}/push-notifications/device-token`,
                    { deviceToken: this.token },
                    { headers: await this.authService.headers() })
                .toPromise()
                .then(resp => {
                    console.log('Token sent', resp);
                });

        }
    }

    private onTokenRefresh(): void {
        if (!this.onTokenRefreshSubscription) {
            this.onTokenRefreshSubscription = this.fcm
                .onTokenRefresh()
                .subscribe(token => {
                    console.log('onTokenRefresh', token);
                    this.token = token;
                    this.sendTokenToServer();
                });
        }
    }

    private async onNotification(): Promise<any> {
        if (!this.onNotificationSubscription) {
            this.onNotificationSubscription = this.fcm
                .onNotification()
                .subscribe(async (data) => {
                    console.log('onNotification', JSON.stringify(data));
                    await this.authService.getMetadata();
                    if (data.wasTapped) {
                        console.log('Received in background', data);
                        if (!data.opportunityId) {
                            this.router.navigate(['/opportunities']);
                            return;
                        }
                        await this.functionHelper.presentLoading('PUSH background');
                        const opportunityResp = await this.opportunitiesService.getOpportunityFromServer(data.opportunityId);
                        await this.functionHelper.dismissLoading('PUSH background');
                        if ('error' in opportunityResp) {
                            this.router.navigate(['/opportunities']);
                            return;
                        }
                        this.router.navigate(['/opportunity-detail', {
                            item: JSON.stringify(opportunityResp),
                            comments: JSON.stringify(opportunityResp.comments),
                            fromPrivate: false,
                            previousPage: 'list',
                            scroll: false
                        }]);
                    } else {

                        const notifAlert = await this.alertCtrl.create({
                            header: this.platform.is('ios') ? data.aps.alert.title : data.title,
                            message: this.platform.is('ios') ? data.aps.alert.body : data.body,
                            cssClass: 'alert--push',
                            buttons: [
                                {
                                    text: ' ',
                                    role: 'cancel',
                                    handler: async () => {
                                        if (!data.opportunityId) {
                                            this.router.navigate(['/opportunities']);
                                            return;
                                        }
                                        await this.functionHelper.presentLoading('PUSH foreground');
                                        const opportunityResp = await this.opportunitiesService.getOpportunityFromServer(data.opportunityId);
                                        await this.functionHelper.dismissLoading('PUSH foreground');
                                        this.opportunitiesService.getOpportunities(0, 15, null, null);
                                        if ('error' in opportunityResp) {
                                            this.router.navigate(['/opportunities']);
                                            return;
                                        }
                                        this.router.navigate(['/opportunity-detail', {
                                            item: JSON.stringify(opportunityResp),
                                            comments: JSON.stringify(opportunityResp.comments),
                                            fromPrivate: false,
                                            previousPage: 'list',
                                            scroll: false
                                        }]);
                                    }
                                }
                            ]
                        });
                        await notifAlert.present();
                        setTimeout(() => {
                            notifAlert.dismiss();
                        }, 5000);

                        console.log('Received in foreground');
                    }

                });
        }
    }
}
