import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from 'sentry-cordova';
import { Platform } from '@ionic/angular';

const SENTRY_DSN = 'https://616a8f30b2364861bef399da4800b696@o329963.ingest.sentry.io/5497472';

@Injectable({
  providedIn: 'root'
}) export class SentryService {

  constructor(private platform: Platform) {
    this.initialize();
  }

  private async initialize(): Promise<void> {
    await this.platform.ready();
    Sentry.init({ dsn: SENTRY_DSN });
  }
}


export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error: any): void {
    super.handleError(error);
    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}
