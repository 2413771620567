import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { DataSelectModalComponent } from '../components/dataSelectModal/dataSelect.component';
import { ApiService } from '../services/api.service';
import { EventsService } from '../services/events.service';
import { AuthService } from '../services/auth.service';


@Injectable()
export class FunctionsHelper {
    private loading = null;
    public optionGeography: Array<any> = [];
    public geographySelectedData = [];

    public constructor(
        private formBuilder: FormBuilder,
        private modalCtrl: ModalController,
        private apiService: ApiService,
        private events: EventsService,
        private authService: AuthService,
        private loadingCtrl: LoadingController
    ) {
    }


    async presentLoading(place) {
        console.log('==== PRESENTING LOADER: ====', place);
        if (!this.loading) {
            this.loading = await this.loadingCtrl.create({});
            await this.loading.present();
        }
        console.log('==== PRESENTED LOADER: ====', place);
    }

    async dismissLoading(place) {
        console.log('==== HIDING LOADER: ====', place);

        if (this.loading) {
            await this.loading.dismiss();
            this.loading = null;
        }
        const loaderEl = document.getElementsByTagName('ion-loading');
        if (loaderEl.length) {
            // loader zustal viset bez reference, odstranime
            loaderEl[0].parentElement.removeChild(loaderEl[0]);
        }
        console.log('==== HIDDEN LOADER: ====', place);

    }

    async presentAreaModal(dataList, selected = null, page, isTree = true) {
        const modal = await this.modalCtrl.create({
            component: DataSelectModalComponent,
            componentProps: {
                data: dataList,
                selected,
                title: 'Select areas',
                subtitle: 'Areas',
                type: 'areas',
                isTree
            },
            cssClass: 'swipableModal',
            swipeToClose: true
        });
        modal.onWillDismiss().then((resp) => {
            switch (page) {
                case 'search':
                    this.events.sendSearchArea({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
                case 'preferences':
                    this.events.sendPreferencesArea({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
            }
        });
        return await modal.present();
    }


    async presentGeographyModal(dataList, page) {
        console.log(dataList);
        const modal = await this.modalCtrl.create({
            component: DataSelectModalComponent,
            componentProps: {
                data: dataList,
                selected: null,
                title: 'Select geography',
                subtitle: 'Geography',
                type: 'geography',
                isTree: true
            },
            cssClass: 'swipableModal',
            swipeToClose: true
        });
        modal.onWillDismiss().then((resp) => {
            switch (page) {
                case 'add':
                    this.events.sendOpportunityAddGeography({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
                case 'search':
                    this.events.sendSearchGeography({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
                case 'preferences':
                    this.events.sendPreferencesGeography({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
            }
        });
        return await modal.present();
    }

    async presentSectorsModal(dataList, selected = null, page, isTree = true) {
        const modal = await this.modalCtrl.create({
            component: DataSelectModalComponent,
            componentProps: {
                data: dataList,
                selected,
                title: 'Select sectors',
                subtitle: 'Sectors',
                type: 'sectors',
                isTree
            },
            cssClass: 'swipableModal',
            swipeToClose: true
        });
        modal.onWillDismiss().then((resp) => {
            switch (page) {
                case 'add':
                    this.events.sendOpportunityAddSectors({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
                case 'search':
                    this.events.sendSearchSectors({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
                case 'preferences':
                    this.events.sendPreferencesSectors({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
            }
        });
        return await modal.present();
    }

    async presentOpportunityTypeModal(dataList, page) {
        const selected = [];
        for (const type of dataList) {
            if (type.isSelected) {
                selected.push(type);
            }
        }
        const modal = await this.modalCtrl.create({
            component: DataSelectModalComponent,
            componentProps: {
                data: dataList,
                selected,
                title: 'Select opportunity types',
                subtitle: 'Opportunity types'
            },
            cssClass: 'swipableModal',
            swipeToClose: true
        });
        modal.onWillDismiss().then((resp) => {
            switch (page) {
                case 'add':
                    this.events.sendOpportunityAddTypes({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
                case 'search':
                    this.events.sendSearchTypes({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
                case 'preferences':
                    this.events.sendPreferencesTypes({
                        data: resp.data.data,
                        dataChanged: resp.data.dataChanged,
                        canceled: resp.data.canceled
                    });
                    break;
            }
        });

        return await modal.present();
    }

    getSectors(metadata) {
        const data = JSON.stringify(metadata.sectors);
        return JSON.parse(data);
    }

    getSubSectors(metadata) {
        const data = JSON.stringify(metadata.subSectors);
        return JSON.parse(data);
    }

    getChildSubSectors(sectorId) {
        const data = JSON.stringify(this.authService.metadata.subSectors);
        let returnedData;
        returnedData = JSON.parse(data).filter(subSector =>
            subSector.sectorId === sectorId
        );
        return returnedData;
    }

    getOppTypes(metadata) {
        const data = JSON.stringify(metadata.opportunityTypes);
        return JSON.parse(data);
    }

    getAreas(metadata) {
        const data = JSON.stringify(metadata.areaOfInterest);
        return JSON.parse(data);
    }

    getSources(metadata) {
        const data = JSON.stringify(metadata.source);
        return JSON.parse(data);
    }


    getGeography(metadata) {
        const data = JSON.stringify(metadata.geographies);
        return JSON.parse(data);
    }


    findIndex(type, array, key) {
        let index;
        switch (type) {
            case 'sectors':
                index = array[type].findIndex(a => a.type === key);
                break;
            case 'geography':
                index = array[type].findIndex(a => a.region === key);
                break;
        }
        return index;
    }

    getTypes(metadata) {
        return [metadata.realEstateType];
    }

    getCountry(regionId, isSubRegions) {
        const data = JSON.stringify(this.authService.metadata.countries);
        let returnedData;
        if (!isSubRegions) {
            returnedData = JSON.parse(data).filter(country =>
                country.geographyId === regionId
            );
        } else {
            returnedData = JSON.parse(data).filter(country =>
                country.subRegionId === regionId
            );
        }
        return returnedData;
    }

    getSubRegions(geoId) {
        const data = JSON.stringify(this.authService.metadata.subRegions);
        let returnedData;
        returnedData = JSON.parse(data).filter(subRegion =>
            subRegion.geographyId === geoId
        );
        return returnedData;
    }

    getAreaTitle(areaId) {
        const areas = this.authService.metadata.areaOfInterest;
        return areaId ? areas[areas.findIndex(a => a.id === areaId)].value : '';
    }

    getOriginatorName(userId, userList) {
        const index = userList.findIndex(u => u.id === userId);
        return userList[index].name;
    }

    getSourceTitle(sourceId): string {
        const sources = this.authService.metadata.source;
        const sourceIndex = sources.findIndex(a => a.id === sourceId);
        const sourceTaxonomyItem = sources[sourceIndex];
        return sourceTaxonomyItem && sourceTaxonomyItem.value;
    }

    getRealEstateTypeTitle(typeId) {
        const types = this.authService.metadata.realEstateType;
        return typeId ? types[types.findIndex(t => t.id === typeId)].value : '';
    }

    hasSelected(parent, children) {
        const index = children.findIndex(ch => ch.isSelected === true);
        return index > -1;
    }

    includesSpecialType(data) {
        for (const type of data) {
            if (this.isSpecialType(type.id)) {
                if (type.isSelected) {
                    return true;
                }
            }
        }
        return false;
    }


    includesSpecialTypeId(data) {
        for (const id of data) {
            if (this.isSpecialType(id)) {
                return true;
            }
        }
        return false;
    }

    hasAnySelected(obj) {
        if (obj.length) {
            let isSelected = -1;
            let abort = false;
            if (!abort) {
                for (const o of obj) {
                    if (!abort) {
                        if (o.subRegions) {
                            isSelected = o.subRegions.findIndex(sr => sr.isSelected === true);
                            if (isSelected > -1) {
                                abort = true;
                                break;
                            }
                            if (!abort) {
                                for (const och of o.subRegions) {
                                    isSelected = och.children.findIndex(ch => ch.isSelected === true);
                                    if (isSelected > -1) {
                                        abort = true;
                                        break;
                                    }
                                }
                            }
                        } else if (o.children) {
                            isSelected = o.children.findIndex(ch => ch.isSelected === true);
                            if (isSelected > -1) {
                                abort = true;
                                break;
                            }
                        } else {
                            if (o.isSelected) {
                                isSelected = 0;
                                abort = true;
                                break;
                            }
                        }
                    }
                }
            }
            return isSelected > -1;

        } else {
            return false;
        }
    }

    hasParentSubRegion(geoId) {
        const subRegions = this.authService.metadata.subRegions;
        return subRegions.findIndex(sr => sr.geographyId === geoId) > -1;
    }

    hasSubRegionAllSelected(item, subRegionId) {
        const countries = this.authService.metadata.countries.filter(country =>
            country.subRegionId === subRegionId
        );
        if (this.includesAny(countries, item.countriesIds)) {
            return false;
        } else {
            return true;
        }
    }

    hasRegionAllSelected(item, regionId) {
        if (this.hasParentSubRegion(regionId)) {
            if (item.subRegionsIds.length === 0) {
                return true;
            }
            const subRegions = this.authService.metadata.subRegions.filter(subRegion =>
                subRegion.geographyId === regionId
            );
            if (this.includesAny(subRegions, item.subRegionsIds)) {
                return false;
            } else {
                return true;
            }
        } else {
            if (item.countriesIds.length === 0) {
                return true;
            }
            const countries = this.authService.metadata.countries.filter(country =>
                country.geographyId === regionId
            );
            if (this.includesAny(countries, item.countriesIds)) {
                return false;
            } else {
                return true;
            }
        }
    }

    includesAny(arr, arr2) {
        return arr.some(i => arr2.includes(i.id));
    }

    selectedSubRegionCountries(item, regionId) {
        const countries = this.authService.metadata.countries.filter(country =>
            country.subRegionId === regionId
        );
        const merged = [];
        for (const countryId of item.countriesIds) {
            if (countries.findIndex(s => s.id === countryId) > -1) {
                merged.push(countryId);
            }
        }
        return merged;
    }

    selectedRegionCountries(item, regionId) {
        const countries = this.authService.metadata.countries.filter(country =>
            country.geographyId === regionId
        );
        const merged = [];
        for (const countryId of item.countriesIds) {
            if (countries.findIndex(s => s.id === countryId) > -1) {
                merged.push(countryId);
            }
        }
        return merged;
    }

    hasAllSelected(item, parentId) {
        const subSectors = this.authService.metadata.subSectors.filter(subSector =>
            subSector.sectorId === parentId
        );
        if (this.includesAny(subSectors, item.subSectorsIds)) {
            return false;
        } else {
            return true;
        }
    }

    getOpportunityTypeTitle(optId) {
        const optType = this.authService.metadata.opportunityTypes.filter(type =>
            type.id === optId
        );
        return optType[0].value;
    }


    getRegionTitle(regionId) {
        const region = this.authService.metadata.geographies.filter(s =>
            s.id === regionId
        );
        return region[0].value;
    }

    getSubRegionTitle(subRegionId) {
        const region = this.authService.metadata.subRegions.filter(sr =>
            sr.id === subRegionId
        );
        return region[0].value;
    }

    getCountryTitle(countryId) {
        const region = this.authService.metadata.countries.filter(c =>
            c.id === countryId
        );
        return region[0].value;
    }

    getSectorTitle(sectorId) {
        const sector = this.authService.metadata.sectors.filter(s =>
            s.id === sectorId
        );
        return sector[0].value;
    }

    getSelectedSectorsTitles(item, sectorId) {
        const subSectors = this.authService.metadata.subSectors.filter(s =>
            s.sectorId === sectorId
        );
        const mergedSubSectors = [];
        for (const subSectorId of item.subSectorsIds) {
            if (subSectors.findIndex(s => s.id === subSectorId) > -1) {
                mergedSubSectors.push(subSectors[subSectors.findIndex(s => s.id === subSectorId)]);
            }
        }
        return mergedSubSectors;
    }


    selectedChildren(children) {
        const selected = [];
        for (const child of children) {
            if (child.isSelected) {
                selected.push(child);
            }
        }
        return selected;
    }


    checkOptions(form, type: string) {
        switch (type) {
            case 'dentonsSources':
                if (!form.controls.dentonsSources.value) {
                    form.controls.externalSources.setValue(true, { onlySelf: true });
                }
                break;
            case 'externalSources':
                if (!form.controls.externalSources.value) {
                    form.controls.dentonsSources.setValue(true, { onlySelf: true });
                }
                break;
        }
    }

    isSpecialType(optId) {
        const array = [2, 15];
        return array.includes(optId);
    }

    getSectorsIdsForSave(dataArray, target) {
        for (const sector of dataArray) {
            if (sector.isSelected) {
                target.sectorsIds.push(sector.id);
            }
            if (!sector.isAllSelected) {
                if (sector.children.length > 0) {
                    for (const child of sector.children) {
                        if (child.isSelected) {
                            target.subSectorsIds.push(child.id);
                        }
                    }
                }
            }
        }
    }

    getGeographyIdsForSave(dataArray, target) {
        for (const region of dataArray) {
            if (region.isSelected) {
                if (target.geographiesIds) {
                    target.geographiesIds.push(region.id);
                }
                if (target.geographies) {
                    target.geographies.push(region.id);
                }
            }
            if (!region.subRegions) {
                if (!region.isAllSelected) {
                    if (region.children.length > 0) {
                        for (const country of region.children) {
                            if (country.isSelected) {
                                if (target.countriesIds) {
                                    target.countriesIds.push(country.id);
                                }
                                if (target.countries) {
                                    target.countries.push(country.id);
                                }
                            }
                        }
                    }
                }
            } else {
                if (!region.isAllSelected) {
                    for (const subRegion of region.subRegions) {
                        if (subRegion.isSelected) {
                            if (target.subRegionsIds) {
                                target.subRegionsIds.push(subRegion.id);
                            }
                            if (target.subRegions) {
                                target.subRegions.push(subRegion.id);
                            }
                        }
                        if (!subRegion.isAllSelected) {
                            if (subRegion.children.length > 0) {
                                for (const country of subRegion.children) {
                                    if (country.isSelected) {
                                        if (target.countriesIds) {
                                            target.countriesIds.push(country.id);
                                        }
                                        if (target.countries) {
                                            target.countries.push(country.id);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    public initGeographyData(item, metadata) {
        this.optionGeography = this.getGeography(metadata);
        if (item && item.geographiesIds.length > 0) {
            for (const geoId of item.geographiesIds) {
                const index = this.optionGeography.findIndex(s => s.id === geoId);
                this.optionGeography[index].isSelected = true;
                this.optionGeography[index].isGeo = true;
                if (this.hasRegionAllSelected(item, geoId)) {
                    this.optionGeography[index].isAllSelected = true;
                    this.optionGeography[index].isClosed = true;
                    if (this.hasParentSubRegion(geoId)) {
                        this.optionGeography[index].subRegions = this.getSubRegions(geoId);
                        for (const subRegion of this.optionGeography[index].subRegions) {
                            subRegion.isSelected = true;
                            if (this.hasSubRegionAllSelected(item, subRegion.id)) {
                                subRegion.isAllSelected = true;
                                subRegion.isClosed = true;
                                subRegion.children = this.getCountry(subRegion.id, true);
                                for (const country of subRegion.children) {
                                    country.isSelected = true;
                                }
                            }
                        }
                    } else {
                        this.optionGeography[index].children = this.getCountry(geoId, false);
                        for (const country of this.optionGeography[index].children) {
                            country.isSelected = true;
                        }

                    }
                } else {
                    this.optionGeography[index].isAllSelected = false;
                    this.optionGeography[index].isClosed = false;
                    if (this.hasParentSubRegion(geoId)) {
                        this.optionGeography[index].subRegions = this.getSubRegions(geoId);
                        for (const subRegion of this.optionGeography[index].subRegions) {
                            subRegion.children = this.getCountry(subRegion.id, true);
                            if (item.subRegionsIds.includes(subRegion.id)) {
                                if (this.hasSubRegionAllSelected(item, subRegion.id)) {
                                    subRegion.isAllSelected = true;
                                    subRegion.isSelected = true;
                                    subRegion.isClosed = false;
                                    for (const country of subRegion.children) {
                                        country.isSelected = true;
                                    }
                                } else {
                                    subRegion.isSelected = true;
                                    subRegion.isAllSelected = false;
                                    subRegion.isClosed = false;
                                    for (const country of subRegion.children) {
                                        if (item.countriesIds.includes(country.id)) {
                                            country.isSelected = true;
                                        } else {
                                            country.isSelected = false;
                                        }
                                    }
                                }
                            } else {
                                subRegion.isSelected = false;
                                subRegion.isAllSelected = false;
                                subRegion.isClosed = true;
                            }
                        }

                    } else {
                        this.optionGeography[index].children = this.getCountry(geoId, false);
                        for (const country of this.optionGeography[index].children) {
                            if (item.countriesIds.includes(country.id)) {
                                country.isSelected = true;
                            }
                        }

                    }
                }
            }


            for (const region of this.optionGeography) {
                if (!item.geographiesIds.includes(region.id)) {
                    region.isSelected = false;
                    region.isAllSelected = false;
                    region.isClosed = true;
                    region.isGeo = true;
                    if (this.hasParentSubRegion(region.id)) {
                        region.subRegions = this.getSubRegions(region.id);
                        for (const subRegion of region.subRegions) {
                            subRegion.children = this.getCountry(subRegion.id, true);
                        }

                    } else {
                        region.children = this.getCountry(region.id, false);
                    }
                }
            }
            this.geographySelectedData = this.optionGeography;
        }
    }

    getFileType(file) {
        const array = file.split('.');
        return array[array.length - 1];
    }
}
