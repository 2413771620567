// id skupin v AD
export const GROUPS = {
    admin_big: '63a080ef-945e-4fa8-9b77-e7288c1c4e0d',
    admin_small: '6c74c249-a58a-4851-b9ea-e557864d2b0e',
    user_regular: '6ffc0da1-c3db-4337-a96b-c83e8327432c'
};

export const SERVER_ENDPOINT = 'https://dot1008.azurewebsites.net';
// export const SERVER_ENDPOINT = 'http://localhost:3000';
export const SERVER_ENDPOINT_API = `${SERVER_ENDPOINT}/api`;
export const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0';
export const GRAPH_ENDPOINT_ME = `${GRAPH_ENDPOINT}/me`;

export const EXPORT_OPPORTUNITIES_MAX_COUNT = 100;

// Pro vyvoj v browseru. Tento token se jinak zjiska pomoci MSAL pluginu. V brwseru ale cordova pluginy nefunguji.
// Token se muze ziskat pomoci administrace, ze se zkopiruje z requestu. Token se musi brat ten, s kterym se vola API serveru. Expiruje po hodine.
export const GRAPH_TOKEN = 'SERVER_TOKEN';
