import { Injectable } from '@angular/core';
import { TaxonomyType } from '../models/taxonomy-type';
import { OtherMetricsConfig } from '../models/other-metric-config';

@Injectable({
    providedIn: 'root'
}) export class OtherMetricsService {

    // TODO generovat konfig podle dat z BE, pokud bude potreba
    metricsConfig: OtherMetricsConfig[] = [
        {
            metricType: 'RE Size',
            metricTypeId: 1,
            title: 'RE size',
            type: 'value',
            suffix: 'sqm',
        },
        {
            metricType: 'RE Type',
            metricTypeId: 2,
            title: 'RE type',
            type: 'taxonomy',
            taxonomyType: TaxonomyType.OTHER_METRICS_REAL_ESTATE_TYPE,
            taxonomyValueKey: 'realEstateType',
        },
        {
            metricType: 'Energy Type',
            metricTypeId: 3,
            title: 'Energy type',
            type: 'taxonomy',
            taxonomyType: TaxonomyType.OTHER_METRICS_ENERGY_TYPE,
            taxonomyValueKey: 'energyType',
            children: [
                {
                    metricType: 'Energy Type',
                    metricTypeId: 3,
                    title: 'Renewables',
                    type: 'taxonomy',
                    taxonomyType: TaxonomyType.OTHER_METRICS_ENERGY_TYPE,
                    taxonomyValueKey: 'energyType',
                    taxonomyFilter: {
                        renewable: true,
                    }
                },
                {
                    metricType: 'Energy Type',
                    metricTypeId: 3,
                    title: 'Non-renewables',
                    type: 'taxonomy',
                    taxonomyType: TaxonomyType.OTHER_METRICS_ENERGY_TYPE,
                    taxonomyValueKey: 'energyType',
                    taxonomyFilter: {
                        renewable: false,
                    }
                },
            ],
        },
        {
            metricType: 'Installed Capacity',
            metricTypeId: 4,
            title: 'Installed capacity',
            type: 'value',
            suffix: 'MW',
        },
    ];


    filterOtherMetricsByConfig(items: any, config: OtherMetricsConfig): any[] {
        return items.filter(it => it.metricTypeId === config.metricTypeId)
            .filter(it => config.taxonomyFilter
                ? Object.keys(config.taxonomyFilter).every(key => config.taxonomyFilter[key] === it[config.taxonomyValueKey][key])
                : true);
    }

    otherMetricsToString(items: any, config: OtherMetricsConfig): string {
        return config.type === 'taxonomy'
            ? items.map(it => it[config.taxonomyValueKey].value).join(', ')
            : `${items[0].value} ${config.suffix}`;
    }

}
