import { Injectable, NgZone } from '@angular/core';
import { NavController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Opportunity } from '../models/opportunity';
import { UtilsService } from './utils.service';
import { SERVER_ENDPOINT_API } from '../constans';
import { HttpErrorResponse } from '../models/httpErrorResponse';
import { OpportunitiesResponse } from '../models/getOpportunitiesResponse';
import { FunctionsHelper } from '../helpers/functions.helper';
import { Attachment } from '../models/attachment';
import { UnsavedAttachment } from '../models/unsavedAttachment';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';

@Injectable()
export class OpportunitiesService {

    public myOpportunities: Array<Opportunity> = [];
    public myFollowedOpportunities: Array<Opportunity> = [];
    public opportunities: Array<Opportunity> = [];

    dividerShowIndex: number;
    myDividerShowIndex: number;


    public constructor(
        private http: HttpClient,
        private utils: UtilsService,
        private navCtrl: NavController,
        private authService: AuthService,
        private ngZone: NgZone,
        private fileTransfer: FileTransfer
    ) {
    }

    public async getOpportunities(offset = 0, limit = 15, type = null, infinite = null, previousPage = null): Promise<OpportunitiesResponse> {
        const pagination = '?offset=' + offset + '&limit=' + limit;
        let apiEndpoint = '/opportunities';
        if (type) {
            switch (type) {
                case 'my': {
                    apiEndpoint = '/opportunities/my';
                    break;
                }
                case 'followed': {
                    apiEndpoint = '/opportunities/followed';
                    break;
                }
            }
        }
        if (previousPage) {
            switch (previousPage) {
                case 'myList': {
                    apiEndpoint = '/opportunities/my';
                    break;
                }
                case 'followedList': {
                    apiEndpoint = '/opportunities/followed';
                    break;
                }
            }
        }

        return this.http
            .get(SERVER_ENDPOINT_API + apiEndpoint + pagination, { headers: await this.authService.headers() })
            .toPromise()
            .then((resp: any) => {
                console.log('GET OPP: ', resp);
                this.ngZone.run(async () => {
                    if (type) {
                        switch (type) {
                            case 'my': {
                                if (infinite) {
                                    this.myOpportunities.push(...resp.data);
                                } else {
                                    this.myOpportunities = [];
                                    this.myOpportunities = resp ? resp.data : [];
                                }
                                break;
                            }
                            case 'followed': {
                                if (infinite) {
                                    this.myFollowedOpportunities.push(...resp.data);
                                } else {
                                    this.myFollowedOpportunities = [];
                                    this.myFollowedOpportunities = resp ? resp.data : [];
                                }
                                this.myDividerShowIndex = resp.newDentontsTotal - 1;
                                break;
                            }
                            default: {
                                if (infinite) {
                                    this.opportunities.push(...resp.data);
                                } else {
                                    this.opportunities = [];
                                    this.opportunities = resp ? resp.data : [];
                                }
                                this.dividerShowIndex = resp.newDentontsTotal - 1;
                                break;
                            }
                        }
                    } else if (previousPage) {
                        switch (previousPage) {
                            case 'myList': {
                                if (infinite) {
                                    this.myOpportunities.push(...resp.data);
                                } else {
                                    this.myOpportunities = [];
                                    this.myOpportunities = resp ? resp.data : [];
                                }
                                break;
                            }
                            case 'followedList': {
                                if (infinite) {
                                    this.myFollowedOpportunities.push(...resp.data);
                                } else {
                                    this.myFollowedOpportunities = [];
                                    this.myFollowedOpportunities = resp ? resp.data : [];
                                }
                                this.myDividerShowIndex = resp.newDentontsTotal - 1;
                                break;
                            }
                            default: {
                                if (infinite) {
                                    this.opportunities.push(...resp.data);
                                } else {
                                    this.opportunities = [];
                                    this.opportunities = resp ? resp.data : [];
                                }
                                this.dividerShowIndex = resp.newDentontsTotal - 1;
                                break;
                            }
                        }
                    } else {
                        if (infinite) {
                            this.opportunities.push(...resp.data);
                        } else {
                            this.opportunities = [];
                            this.opportunities = resp ? resp.data : [];
                        }
                        this.dividerShowIndex = resp.newDentontsTotal - 1;
                    }
                });
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                this.utils.presentToast(error.message);
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/azure-login');
                }
                return undefined;
            });
    }

    public async newOpportunity(data): Promise<Opportunity | HttpErrorResponse> {
        return this.http
            .post<Opportunity>(`${SERVER_ENDPOINT_API}/opportunities`, data, { headers: await this.authService.headers() })
            .toPromise()
            .then((resp: any) => {
                console.log('NEW OPP RESP:', resp);

                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/login');
                }
                return error;
            });
    }

    public async editOpportunity(data): Promise<Opportunity | HttpErrorResponse> {
        return this.http
            .put<Opportunity>(`${SERVER_ENDPOINT_API}/opportunities`, data, { headers: await this.authService.headers() })
            .toPromise()
            .then(resp => {
                console.log('EDIT OPP RESP:', resp);
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                console.log(error);
                this.utils.presentToast(error.message);
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/login');
                }
                return error;
            });
    }

    public async uploadFile(oppportunityId: number, file: UnsavedAttachment): Promise<Attachment | HttpErrorResponse> {

        const split = file.filename.split('.');
        const extension = split.pop();
        const nameWithoutExt = split.join('.');
        const clippedName = nameWithoutExt.substr(0, 255 - extension.length - 1);

        const headers = await this.authService.headers();
        delete headers['Content-Type'];
        const params = oppportunityId === undefined ? undefined : {
            opportunityId: '' + oppportunityId
        };

        const ft = this.fileTransfer.create();
        try {
            const resp = await ft.upload(
                file.localUrl,
                `${SERVER_ENDPOINT_API}/opportunities/upload`,
                {
                    fileName: `${clippedName}.${extension}`,
                    fileKey: 'files',
                    mimeType: file.mimeType,
                    headers,
                    params
                }
            );
            const attachment: Attachment = JSON.parse(resp.response);
            return attachment[0];
        } catch (err) {
            console.log(err);
            const error: HttpErrorResponse = JSON.parse(err.body);
            this.utils.presentToast(error.message);
            if (err.http_status === 401) {
                // TODO jak se dela logout? Co se stavem aplikace, odhlaseni PN apod.
                this.navCtrl.navigateRoot('/login');
            }
            return error;
        }
    }

    public async commentOpportunity(comment, commentType): Promise<Array<Opportunity>> {
        return this.http
            .post(`${SERVER_ENDPOINT_API}/opportunities/comments/${commentType}`, comment, { headers: await this.authService.headers() })
            .toPromise()
            .then(async (resp: any) => {
                console.log(resp);
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                console.log(error);
                this.utils.presentToast(error.message);
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/login');
                }
                return undefined;
            });
    }

    public async removeOpportunityComment(comment, commentType): Promise<Array<Opportunity>> {
        return this.http
            .delete(`${SERVER_ENDPOINT_API}/opportunities/comments/${commentType}/${comment.id}`, { headers: await this.authService.headers() })
            .toPromise()
            .then(async (resp: any) => {
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                console.log(error);
                this.utils.presentToast(error.message);
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/login');
                }
                return undefined;
            });
    }

    public async editOpportunityComment(comment, commentType): Promise<Array<Opportunity>> {
        return this.http
            .put(`${SERVER_ENDPOINT_API}/opportunities/comments/${commentType}`, comment, { headers: await this.authService.headers() })
            .toPromise()
            .then(async (resp: any) => {
                console.log('Edit COMMENT RESP', resp);
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                console.log(error);
                this.utils.presentToast(error.message);
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/login');
                }
                return undefined;
            });
    }

    public async handleFollowing(opportunity, following, lastPage): Promise<any> {
        return this.http
            .post(`${SERVER_ENDPOINT_API}/opportunities/${opportunity.id}/follow`, { follow: following }, { headers: await this.authService.headers() })
            .toPromise()
            .then(async (resp: any) => {
                await this.updateLists(resp, lastPage);
            })
            .catch((error: HttpErrorResponse) => {
                console.log(error);
            });
    }

    public async updateLists(opportunity, previousPage = null) {
        await this.ngZone.run(async () => {
            await this.getOpportunities(0, 15, null, null, previousPage);
            const indexOpps = this.opportunities.findIndex(opp => opp.id === opportunity.id);
            const indexMyOpps = this.myOpportunities.findIndex(opp => opp.id === opportunity.id);
            const indexMyOppsFollowed = this.myFollowedOpportunities.findIndex(opp => opp.id === opportunity.id);
            if (indexOpps > -1) {
                this.opportunities[indexOpps].followersCount = opportunity.followersCount;
                this.opportunities[indexOpps].followers = opportunity.followers;
                this.opportunities[indexOpps].isFollowing = opportunity.isFollowing;
            }
            if (indexMyOpps > -1) {
                this.myOpportunities[indexMyOpps].followersCount = opportunity.followersCount;
                this.myOpportunities[indexMyOpps].followers = opportunity.followers;
                this.myOpportunities[indexMyOpps].isFollowing = opportunity.isFollowing;
            }
            if (indexMyOppsFollowed > -1) {
                this.myFollowedOpportunities[indexMyOppsFollowed].followersCount = opportunity.followersCount;
                this.myFollowedOpportunities[indexMyOppsFollowed].followers = opportunity.followers;
                this.myFollowedOpportunities[indexMyOppsFollowed].isFollowing = opportunity.isFollowing;
            }
        });
    }

    getOpportunity(opportunityId): Opportunity {
        const index = this.opportunities.findIndex(opp => opp.id === opportunityId);
        return this.opportunities[index];
    }

    async getOpportunityFromServer(opportunityId): Promise<Opportunity | HttpErrorResponse> {
        return this.http
            .get(`${SERVER_ENDPOINT_API}/opportunities/${opportunityId}`, { headers: await this.authService.headers() })
            .toPromise()
            .then(async (resp: any) => {
                console.log('GET OPPORTUNITY BY ID', resp);
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                console.log(error);
                this.utils.presentToast(error.message);
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/login');
                }
                return error;
            });
    }





}
