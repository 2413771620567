import { Component, Input, OnInit } from '@angular/core';
import { Opportunity } from '../../models/opportunity';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { OpportunityHelper } from '../../helpers/opportunity.helper';
import { ExportService } from 'src/app/services/export.service';
import { OpportunitiesService } from '../../services/opportunities.service';
import { ShareService } from 'src/app/services/share.service';

@Component({
    selector: 'opportunity-box',
    templateUrl: 'opportunityBox.component.html',
    styleUrls: ['opportunityBox.component.scss']
})
export class OpportunityBoxComponent implements OnInit {
    @Input() public lastPage: string;
    @Input() public item;
    @Input() public isPrivate = false;

    checked = false;
    constructor(
        public oppHelper: OpportunityHelper,
        public exportSvc: ExportService,
        public opportunityService: OpportunitiesService,
        private router: Router,
        private shareService: ShareService,
    ) {
    }

    ngOnInit() {
    }

    onItemClick() {
        if (this.exportSvc.ticksShown) {
            this.exportSvc.toggleChecked(this.item.id);
        } else {
            this.navTo('/opportunity-detail', this.item);
        }
    }

    public editItem(item) {
        this.router.navigate(['/opportunity-add', {
            item: JSON.stringify(item)
        }]);
    }

    public navTo(page, item: Opportunity, scroll = false) {
        this.router.navigate([page, {
            item: JSON.stringify(item),
            comments: JSON.stringify(item.comments),
            fromPrivate: this.isPrivate,
            previousPage: this.lastPage,
            scroll
        }]);
    }

    onShareClick(): void {
        this.shareService.share(this.item);
    }

    transformDate(date, format) {
        return moment(date).format(format);
    }
}
