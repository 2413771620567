export enum TaxonomyType {
    OTHER_METRICS = 1,
    OTHER_METRICS_ENERGY_TYPE,
    OTHER_METRICS_REAL_ESTATE_TYPE,
    SECTOR,
    SUBSECTOR,
    GEOGRAPHY,
    SUBREGION,
    COUNTRY,
    OPPORTUNITY_TYPE,
    SOURCE,
}
