import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'opportunities',
        loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
    }, {
        path: '',
        loadChildren: () => import('./pages/initPage/init.module').then(m => m.InitPageModule)
    }, {
        path: 'azure-login',
        loadChildren: () => import('./pages/azureLoginPage/azureLogin.module').then(m => m.AzureLoginPageModule)
    }, {
        path: 'login',
        loadChildren: () => import('./pages/loginPage/login.module').then(m => m.LoginPageModule)
    }, {
        path: 'onboarding',
        loadChildren: () => import('./pages/onboardingPage/onboarding.module').then(m => m.OnboardingPageModule)
    }, {
        path: 'opportunity-detail',
        loadChildren: () => import('./pages/opportunity/detailPage/opportunityDetail.module').then(m => m.OpportunityDetailPageModule)
    }, {
        path: 'opportunity-add',
        loadChildren: () => import('./pages/opportunity/addPage/opportunityAdd.module').then(m => m.OpportunityAddPageModule)
    }, {
        path: 'about',
        loadChildren: () => import('./pages/aboutPage/about.module').then(m => m.AboutModule)
    }, {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
    }, {
        path: 'notifications-detail',
        loadChildren: () => import('./pages/notifications/detail/notificationDetail.module').then(m => m.NotificationDetailPageModule)
    }, {
        path: 'preferences',
        loadChildren: () => import('./pages/preferencesPage/preferences.module').then(m => m.PreferencesPageModule)
    }, {
        path: 'search',
        loadChildren: () => import('./pages/searchPage/search.module').then(m => m.SearchPageModule)
    }, {
        path: 'search-results',
        loadChildren: () => import('./pages/searchResultsPage/searchResults.module').then(m => m.SearchResultsPageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
