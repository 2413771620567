import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Injectable } from '@angular/core';
import { EventsService } from './events.service';
import { FunctionsHelper } from '../helpers/functions.helper';
import { OpportunitiesService } from './opportunities.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DeeplinkService {

    startupComplete = false;
    coldStartLink: { path: string, queryString: string };

    constructor(
        private deeplinks: Deeplinks,
        private eventsSvc: EventsService,
        private functionHelper: FunctionsHelper,
        private opportunitiesService: OpportunitiesService,
        private router: Router,
        private authSvc: AuthService,
    ) {
        this.eventsSvc.getAppStartup().subscribe(startupComplete => {
            this.startupComplete = startupComplete;
            if (startupComplete && this.coldStartLink && this.authSvc.user) {
                this.handleDeeplink(this.coldStartLink);
                this.coldStartLink = undefined;
            }
        });
    }

    init() {
        this.subscribeHandlers();
    }

    subscribeHandlers() {
        this.deeplinks.route({})
            .subscribe(
                _routeMatch => {
                    // direct navigation - unused
                },
                event => {
                    console.log('Deep link received', event);
                    // handle navigation manually here
                    // routeNoMatch.$link - the full link data
                    if (!event.$link || !event.$link.url) {
                        return;
                    }
                    if (this.startupComplete && this.authSvc.user) {
                        this.handleDeeplink(event.$link);
                    } else {
                        // save link to handle navigation after startup completed
                        this.coldStartLink = event.$link;
                    }
                    // always resubscribe due to plugin bug, https://github.com/ionic-team/ionic-plugin-deeplinks/issues/77
                    this.subscribeHandlers();
                });
    }

    async handleDeeplink(link: { path: string, queryString: string }) {
        const urlParams = new URLSearchParams(link.queryString);
        const oppId = urlParams.get('opportunity');
        if (!oppId) {
            console.warn('handleDeeplink no opportunity ID found');
            return;
        }
        await this.functionHelper.presentLoading('deeplinks');
        const opportunityResp = await this.opportunitiesService.getOpportunityFromServer(oppId);
        await this.functionHelper.dismissLoading('deeplinks');
        if ('error' in opportunityResp) {
            return;
        }
        this.router.navigate(['/opportunity-detail', {
            item: JSON.stringify(opportunityResp),
            comments: JSON.stringify(opportunityResp.comments),
            fromPrivate: false,
            previousPage: 'list',
            scroll: false
        }]);
    }
}
