import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable()
export class UtilsService {

    private loader: HTMLIonLoadingElement;

    public constructor(
        private toastCtrl: ToastController,
        private loadingCtrl: LoadingController,
        private alertCtrl: AlertController,
    ) {
    }

    public async presentToast(message: string, duration: number = 3000): Promise<void> {
        const toast = await this.toastCtrl.create({
            message,
            duration
        });
        await toast.present();
    }

    public presentErrorAlert(message: string): void {
        this.alertCtrl.create({
            header: 'Error',
            message,
            buttons: [{
                text: 'OK',
            }]
        }).then(alert => alert.present());
    }

    public async presentLoader(): Promise<void> {
        this.loader = await this.loadingCtrl.create({
            spinner: 'crescent'
        });
        return this.loader.present();
    }

    public async dismissLoader(): Promise<boolean> {
        return this.loader && await this.loader.dismiss();
    }
}
