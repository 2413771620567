import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-checkbox-tree',
    templateUrl: 'checkboxTree.component.html',
    styleUrls: ['checkboxTree.component.scss']
})
export class CheckboxTreeComponent implements OnInit {
    @Input() public dataArray: Array<any> = [];
    @Input() public title;

    public initialSelected: Array<string> = [];
    public checksArray: Array<{ name: string, isSelected: boolean, display: boolean }>;
    public selected = [];

    constructor(
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private ngZone: NgZone,
        public apiService: ApiService,
        public authService: AuthService
    ) {
        this.dataArray = this.navParams.get('data');
        this.initialSelected = this.navParams.get('selected') ? this.navParams.get('selected').split(', ') : [];
        this.title = this.navParams.get('title');
    }

    ngOnInit() {
        this.selected = [];
    }

    handleSelect(model) {
        if (model.isSelected) {
            this.selected.push(model.name);
        } else {
            this.selected.splice(this.selected.indexOf(model.name), 1);
        }
    }

    close() {
        this.modalCtrl.dismiss({
            data: this.dataArray,
            canceled: true
        });
    }

    done() {
        this.modalCtrl.dismiss({
            data: this.dataArray,
            canceled: false
        });
    }

    filter(event) {
        const query = event.target.value;
        this.ngZone.run(() => {
            if (query && query.trim() !== '') {
                this.checksArray = this.checksArray.filter((item) => {
                    return (item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
                });
            }
        });
    }

    // Expand/Collapse event on each parent
    expandCollapse(obj) {
        if (!obj.isGeo) {
            if (obj.children.length === 0) {
                this.getSubSectors(obj);
            }
        } else {
            if (!this.hasParentSubRegion(obj)) {
                if (obj.children && obj.children.length === 0) {
                    this.getCountries(obj);
                }
            } else {
                if (obj.subRegions && obj.subRegions.length === 0) {
                    this.getSubRegions(obj);
                }
            }
        }
        obj.isClosed = !obj.isClosed;
    }

    // Expand/Collapse event on each child
    expandCollapseChild(obj) {
        if (obj.children.length === 0) {
            this.getCountries(obj, true);
        }
        obj.isClosed = !obj.isClosed;
    }


    getSubSectors(obj) {
        const data = JSON.stringify(this.authService.metadata.subSectors);
        return JSON.parse(data).forEach(subSector => {
            if (subSector.sectorId === obj.id) {
                subSector.isSelected = false;
                if (obj.isAllSelected) {
                    subSector.isSelected = true;
                }
                obj.children.push(subSector);
            }
        });
    }

    getCountries(obj, nested = false) {
        const data = JSON.stringify(this.authService.metadata.countries);
        return JSON.parse(data).forEach(country => {
            if (!nested) {
                if (country.geographyId === obj.id) {
                    country.isSelected = false;
                    if (obj.isAllSelected) {
                        country.isSelected = true;
                    }
                    obj.children.push(country);
                }
            } else {
                if (country.subRegionId === obj.id) {
                    country.isSelected = false;
                    if (obj.isAllSelected) {
                        country.isSelected = true;
                    }
                    obj.children.push(country);
                }
            }
        });

    }


    getSubRegions(obj) {
        const data = JSON.stringify(this.authService.metadata.subRegions);

        return JSON.parse(data).forEach(subRegion => {
            if (subRegion.geographyId === obj.id) {
                subRegion.isSelected = false;
                subRegion.isAllSelected = false;
                if (obj.isAllSelected) {
                    subRegion.isSelected = true;
                    subRegion.isAllSelected = true;
                }
                subRegion.isClosed = true;
                subRegion.children = [];

                obj.subRegions.push(subRegion);
            }
        });

    }


    parentCheck(parentObj) {
        parentObj.isAllSelected = parentObj.isSelected;
        if (parentObj.subRegions) {
            if (parentObj.subRegions.length === 0) {
                this.getSubRegions(parentObj);
            }
            for (const subRegion of parentObj.subRegions) {
                if (subRegion.children.length === 0) {
                    this.getCountries(subRegion, true);
                }

                subRegion.isSelected = parentObj.isSelected;
                subRegion.isAllSelected = parentObj.isSelected;
                for (const c of subRegion.children) {
                    c.isSelected = parentObj.isSelected;
                }
            }
        } else {
            if (parentObj.children.length === 0) {
                if (!parentObj.isGeo) {
                    this.getSubSectors(parentObj);
                } else {
                    this.getCountries(parentObj);
                }
            } else {
                for (const c of parentObj.children) {
                    c.isSelected = parentObj.isSelected;
                }
            }
        }
    }

    childCheck(rootObj, parentObj, childObj) {
        parentObj.isAllSelected = childObj.every((itemChild: any) => {
            return itemChild.isSelected === true;
        });

        if (rootObj) {
            rootObj.isAllSelected = rootObj.subRegions.every((itemChild: any) => {
                return itemChild.isAllSelected === true;
            });
            if ((childObj.findIndex(ch => ch.isSelected === true)) > -1) {
                parentObj.isSelected = true;
                rootObj.isSelected = true;
            } else {
                parentObj.isSelected = false;
                rootObj.isSelected = false;
            }
            if (rootObj.subRegions.findIndex(subR => subR.isSelected === true) > -1) {
                rootObj.isSelected = true;
            } else {
                rootObj.isSelected = false;
            }
        } else {
            if (childObj.findIndex(ch => ch.isSelected === true) > -1) {
                parentObj.isSelected = true;
            } else {
                parentObj.isSelected = false;

            }
        }
    }

    subparentCheck(childObj, parentObj) {
        childObj.isAllSelected = childObj.isSelected;
        for (const c of childObj.children) {
            c.isSelected = childObj.isSelected;
        }
        parentObj.isAllSelected = parentObj.subRegions.every((itemChild: any) => {
            return itemChild.isAllSelected === true;
        });
        if ((parentObj.subRegions.findIndex(ch => ch.isSelected === true)) > -1) {
            parentObj.isSelected = true;
        } else {
            parentObj.isSelected = false;
        }
        if (childObj.children.length === 0) {
            this.getCountries(childObj, true);
        }
    }

    hasParentSubRegion(region) {
        const subRegions = this.authService.metadata.subRegions;
        return subRegions.findIndex(sr => sr.geographyId === region.id) > -1;

    }
}
