import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ExportService } from '../../services/export.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ExportFormat } from 'src/app/models/exportFormatEnum';

@Component({
    selector: 'header-sub-toolbar',
    templateUrl: 'headerSubToolbar.component.html',
    styleUrls: ['headerSubToolbar.component.scss']
})
export class HeaderSubToolbarComponent implements OnInit {

    constructor(
        private alertCtrl: AlertController,
        public exportSrv: ExportService,
        private utilSvc: UtilsService,
    ) {

    }

    ngOnInit() {
    }

    public showExportTicks() {
        this.exportSrv.showTicks();
    }

    public cancelSelection() {
        this.exportSrv.clear();
    }

    public async makeExport(exportAll: boolean) {
        if (!exportAll && this.exportSrv.checkedItemsTotal === 0) {
            await this.utilSvc.presentToast('Please make a selection.');
            return;
        }
        const exportFormat = await this.presentExportTypeAlert();
        if (!exportFormat) {
            this.exportSrv.clear();
            return;
        }
        if (exportAll) {
            const confirmed = await this.presentExportAllLimitAlert();
            if (!confirmed) {
                this.exportSrv.clear();
                return;
            }
        }
        this.exportSrv.doExport(exportAll, exportFormat);
    }

    public async presentExportTypeAlert(): Promise<ExportFormat> {
        const alert = await this.alertCtrl.create({
            cssClass: 'alert--export',
            header: 'Export format',
            inputs: [
                {
                    cssClass: 'checkbox-pdf',
                    name: 'checkbox',
                    type: 'radio',
                    label: 'PDF',
                    value: ExportFormat.PDF,
                },
                {
                    cssClass: 'checkbox-doc',
                    name: 'checkbox',
                    type: 'checkbox',
                    label: 'Microsoft Word',
                    value: ExportFormat.WORD,
                },
                {
                    cssClass: 'checkbox-ppt',
                    name: 'checkbox',
                    type: 'radio',
                    label: 'Microsoft PowerPoint',
                    value: ExportFormat.POWERPOINT,
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'EXPORT',
                    role: 'export'
                }
            ]
        });

        await alert.present();
        const result = await alert.onDidDismiss();
        if (result?.role === 'export') {
            if (!result.data.values) {
                this.utilSvc.presentToast('Please make a selection.');
                return await this.presentExportTypeAlert();
            }
            return result.data.values;
        }
        return undefined;
    }

    public async presentExportAllLimitAlert(): Promise<boolean> {
        const alert = await this.alertCtrl.create({
            header: 'Export info',
            subHeader: 'There will be exported only ' + this.exportSrv.exportLimit + ' opportunities from the beginning of the list.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                }, {
                    text: 'EXPORT',
                    role: 'export',
                }
            ]
        });
        await alert.present();
        const result = await alert.onDidDismiss();
        return result?.role === 'export';
    }
}
