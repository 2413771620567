import { NgModule } from '@angular/core';
import { OpportunityBoxComponent } from './opportunityBox/opportunityBox.component';
import { IonicModule } from '@ionic/angular';
import { CheckboxTreeComponent } from './checkboxTree/checkboxTree.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxTreeInputsComponent } from './checkboxTreeInputs/checkboxTreeInputs.component';
import { HeaderSubToolbarComponent } from './headerSubToolbar/headerSubToolbar.component';

@NgModule({
    declarations: [OpportunityBoxComponent, CheckboxTreeComponent, CheckboxTreeInputsComponent, HeaderSubToolbarComponent],
    imports: [
        IonicModule,
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
    ],
    exports: [OpportunityBoxComponent, CheckboxTreeComponent, CheckboxTreeInputsComponent, HeaderSubToolbarComponent]
})
export class ComponentsModule {
}
