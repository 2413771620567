import { Injectable, NgZone } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Opportunity } from '../models/opportunity';
import { UtilsService } from './utils.service';
import { HttpErrorResponse } from '../models/httpErrorResponse';
import { OpportunitiesResponse } from '../models/getOpportunitiesResponse';
import { FunctionsHelper } from '../helpers/functions.helper';
import { SERVER_ENDPOINT_API } from '../constans';

@Injectable()
export class PreferencesService {

    public preferences;


    public constructor(
        private toastCtrl: ToastController,
        private http: HttpClient,
        private utils: UtilsService,
        private navCtrl: NavController,
        private authService: AuthService
    ) {
    }


    public async editPreferences(data): Promise<any> {
        return this.http
            .put(`${SERVER_ENDPOINT_API}/user/preferences`, data, { headers: await this.authService.headers() })
            .toPromise()
            .then((resp: any) => {
                return resp as Array<Opportunity>;
            })
            .catch((error: HttpErrorResponse) => {
                console.log(error);
                this.utils.presentToast(error.message);
                if (error.status === 401) {
                    this.navCtrl.navigateRoot('/login');
                }
                return undefined;
            });
    }

}
