import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    constructor() {
    }

    private mySubmissions = new Subject<any>();
    private notificationType = new Subject<any>();

    private subjectGeo = new Subject<any>();
    private subjectSectors = new Subject<any>();
    private subjectTypes = new Subject<any>();
    private subjectAreas = new Subject<any>();
    private appStartup = new BehaviorSubject<boolean>(false);

    sendNotificationType(text) {
        this.notificationType.next(text);
    }

    getNotificationType(): Observable<any> {
        return this.notificationType.asObservable();
    }

    sendEditOpportunity(text) {
        this.mySubmissions.next(text);
    }

    getEditOpportunity(): Observable<any> {
        return this.mySubmissions.asObservable();
    }

    sendAppStartup() {
        this.appStartup.next(true);
    }

    getAppStartup(): Observable<boolean> {
        return this.appStartup.asObservable();
    }

    sendOpportunityAddGeography(text) {
        this.subjectGeo.next(text);
    }

    sendOpportunityAddSectors(text) {
        this.subjectSectors.next(text);
    }

    sendOpportunityAddTypes(text) {
        this.subjectTypes.next(text);
    }

    sendSearchGeography(text) {
        this.subjectGeo.next(text);
    }

    sendSearchSectors(text) {
        this.subjectSectors.next(text);
    }

    sendPreferencesSectors(text) {
        this.subjectSectors.next(text);
    }

    sendSearchTypes(text) {
        this.subjectTypes.next(text);
    }

    sendPreferencesTypes(text) {
        this.subjectTypes.next(text);
    }

    sendSearchArea(text) {
        this.subjectAreas.next(text);
    }

    sendPreferencesArea(text) {
        this.subjectAreas.next(text);
    }

    sendPreferencesGeography(text) {
        this.subjectGeo.next(text);
    }

    getOpportunityAddGeography(): Observable<any> {
        return this.subjectGeo.asObservable();
    }

    getOpportunityAddSectors(): Observable<any> {
        return this.subjectSectors.asObservable();
    }

    getOpportunityAddTypes(): Observable<any> {
        return this.subjectTypes.asObservable();
    }

    getSearchGeography(): Observable<any> {
        return this.subjectGeo.asObservable();
    }

    getSearchOpportunityTypes(): Observable<any> {
        return this.subjectTypes.asObservable();
    }

    getPreferencesOpportunityTypes(): Observable<any> {
        return this.subjectTypes.asObservable();
    }

    getSearchSectors(): Observable<any> {
        return this.subjectSectors.asObservable();
    }


    getPreferencesGeography(): Observable<any> {
        return this.subjectGeo.asObservable();
    }

    getPreferencesSectors(): Observable<any> {
        return this.subjectSectors.asObservable();
    }

    getSearchAreas(): Observable<any> {
        return this.subjectAreas.asObservable();
    }
    getPreferencesAreas(): Observable<any> {
        return this.subjectAreas.asObservable();
    }

}
