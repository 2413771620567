import { Injectable } from '@angular/core';
import { SERVER_ENDPOINT_API } from '../constans';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { AuthService } from './auth.service';
import { OpportunitiesResponse } from '../models/getOpportunitiesResponse';

@Injectable()
export class SearchService {

    public constructor(
        private http: HttpClient,
        private utils: UtilsService,
        private authService: AuthService
    ) {
    }

    async fulltextSearch(data): Promise<OpportunitiesResponse> {
        return this.http
            .post(`${SERVER_ENDPOINT_API}/opportunities/search-simple`, data, { headers: await this.authService.headers() })
            .toPromise()
            .then(async (resp) => {
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                this.utils.presentToast(error.error.message);
                return undefined;
            });
    }
    async search(data): Promise<OpportunitiesResponse> {
        return this.http
            .post(`${SERVER_ENDPOINT_API}/opportunities/search`, data, { headers: await this.authService.headers() })
            .toPromise()
            .then(async (resp) => {
                return resp;
            })
            .catch((error: HttpErrorResponse) => {
                this.utils.presentToast(error.error.message);
                return undefined;
            });
    }
}
