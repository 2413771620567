import { Component } from '@angular/core';

import { MenuController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { AnalyticsService } from './services/analytics.service';
import { PushService } from './services/push.service';
import { SentryService } from './services/sentry.service';
import { User } from './models/user';
import { EventsService } from './services/events.service';
import { DeeplinkService } from './services/deepLinks.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public page = '';

    public appPages = [
        {
            title: 'Opportunity Feed',
            url: '/opportunities'
        },
        {
            title: 'Submit the Opportunity',
            url: '/opportunity-add'
        },
        {
            title: 'Search',
            url: '/search'
        },
        {
            title: 'Preferences',
            url: '/preferences'
        },
        {
            title: 'Notifications',
            url: '/notifications'
        },
        {
            title: 'FAQ & Contacts',
            url: '/about'
        }
    ];

    constructor(
        public authService: AuthService,
        public menuCtrl: MenuController,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private navCtrl: NavController,
        private analyticsService: AnalyticsService,
        private pushService: PushService,
        private eventsSvc: EventsService,
        public deeplinkSvc: DeeplinkService,
        public sentrySvc: SentryService, // auto-initialized service
    ) {
        this.initializeApp();

    }

    initializeApp() {
        this.platform.ready().then(async () => {
            this.statusBar.styleLightContent();
            this.splashScreen.hide();
            this.analyticsService.initialize();
            this.deeplinkSvc.init();
            await this.pushService.initialize();
            const logged = JSON.parse(localStorage.getItem('DOT-logged'));

            let azureUser: User;

            if (logged) {
                azureUser = await this.authService.getUserInfoWithStats();
            }

            if (logged && azureUser) {
                const user = JSON.parse(localStorage.getItem('DOT-user'));
                if (user.isOnboarded !== true) {
                    await this.navCtrl.navigateRoot('/onboarding');
                } else if (user.isOnboarded === true && user.isPreferencesSet !== true) {
                    await this.navCtrl.navigateRoot('/preferences');
                } else {
                    await this.navCtrl.navigateRoot('/opportunities');
                }
            } else {
                await this.navCtrl.navigateRoot('/azure-login');
            }

            this.eventsSvc.sendAppStartup();
        });
    }

    makeMonogram(user) {
        let monogram: string;
        const nameArray = user.name.split(' ');
        monogram = nameArray[0][0] + nameArray[1][0];
        return monogram;
    }

    async navTo(page) {
        if (page === '/azure-login') {
            await this.menuCtrl.toggle();
            this.navCtrl.navigateRoot('/azure-login');
            return;
        } else {
            await this.menuCtrl.toggle();
        }
        if (this.router.url.toLowerCase().indexOf('/opportunities') > -1) {
            await this.router.navigateByUrl(page);
        } else {
            await this.router.navigateByUrl(page, { replaceUrl: true });
        }
    }

    public async signOut() {
        await this.pushService.deleteTokenOnServer();
        await this.authService.signOut(true);
        this.navTo('/azure-login');
    }
}
